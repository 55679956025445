import React, { useEffect, useState } from "react";

import Loader from "../components/loader/Loader";
import { useDispatch, useSelector } from "react-redux";
import { loginAdmin } from "../redux/thunk/loginThunk";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordType, setPasswordType] = useState(true);
  const { loginData, loading } = useSelector((state) => state.loginState);

  const handleLoginSubmit = async (event) => {
    event.preventDefault();

    if (!email) {
      toast.error("Email is Required");
      return false;
    }
    if (!password) {
      toast.error("Password is Required");
      return false;
    }

    try {
      const data = {
        email: email,
        password: password,
      };
      if (data) {
        dispatch(loginAdmin(data));
      }
    } catch (err) {}
  };

  useEffect(() => {
    if (loginData?.success) {
      navigate("/dashboard");
    }
  }, [loginData?.success, navigate]);
  useEffect(() => {
    const token = localStorage.getItem("siliconToken");
    if (token) {
      navigate("/dashboard");
    }
  }, [navigate]);
  return (
    <>
      {loading && <Loader isActive={true} />}
      <div className="loginform">
        <div className="loginform-loginformtop">
          <div className="loginform-loginformtop--loginformtoptext">
            <h1>Welcome Back</h1>
            <h2>
              Sign in to Continue to <strong>Reflex LMS</strong> Dashboard
            </h2>
          </div>
          {/* <div className="loginform-loginformtop--loginformtopimage">
            <img src={logo} alt="" height="100%" width="100%" />
          </div> */}
          <div className="loginform-loginformtop--loginformtopimagelogo">
            <div className="loginform-loginformtop--loginformtopimagelogo_text">
              {/* <h1>Reflex</h1> */}
              <h2 style={{ textAlign: "center" }}>
                Reflex <br /> LMS
              </h2>
            </div>
          </div>
        </div>

        <form className="loginform-loginformform" onSubmit={handleLoginSubmit}>
          <div>
            <label htmlFor="email">Email</label>
            <input
              type="text"
              placeholder="Enter your email ..."
              id="email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>

          <div>
            <label htmlFor="password">Password</label>
            <div className="passwordbutton">
              <input
                type={passwordType ? "password" : "text"}
                id="password"
                name="password"
                placeholder="Enter your password ..."
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <button
                onClick={(e) => {
                  e.preventDefault();
                  setPasswordType(!passwordType);
                }}
                className="passwordbutton-button"
              >
                {passwordType ? (
                  <i className="fa-regular fa-eye-slash"></i>
                ) : (
                  <i className="fa-regular fa-eye"></i>
                )}
              </button>
            </div>
          </div>

          <div className="submitbtn">
            <input type="submit" value="Login" className="secondary-button" />
          </div>
        </form>
      </div>
    </>
  );
};

export default Login;
